import React, { useState } from "react";
import AzureAuthenticationButton from "./azure/azure-authentication-component";
import { AccountInfo } from "@azure/msal-browser";
import App from "./App";
import './App.css';

function Authenticator() {
  // current authenticated user
  const [currentUser, setCurrentUser] = useState<AccountInfo>();

  const [userEmail, setUserEmail] = useState('');

  // authentication callback
  const onAuthenticated = async (userAccountInfo: AccountInfo) => {
    setUserEmail(userAccountInfo.username);
    setCurrentUser(userAccountInfo);
  };

  // Render JSON data in readable format
  const PrettyPrintJson = ({ data }: any) => {
    return (
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };

  // Quick link - user revokes app's permission
  const ShowPermissionRevokeLinks = () => {
    return (
      <div>
        <div><a href="https://myapps.microsoft.com" target="_blank" rel="noopener">Revoke AAD permission</a></div>
        <div><a href="https://account.live.com/consent/manage" target="_blank" rel="noopener">Revoke Consumer permission</a></div>
      </div>
    );
  };

  return (
    //<div id="App">
    // below div <h1 className='name-text' justify-content='center'>Playlist Impact</h1>
    <div id="App">
      {currentUser && (
        <App userEmail={userEmail} />
      )}
      <AzureAuthenticationButton onAuthenticated={onAuthenticated} />
      
    </div>
  );
}

export default Authenticator;