import './App.css';
import React from 'react';
import { useState, useRef } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton, Button, ButtonGroup, Alert, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { jsonToCSV }  from 'react-papaparse';
import ReactTooltip from 'react-tooltip';

const { useEffect } = React;

const useTypewriter = (message = []) => {
  const [txt, setTxt] = useState('');
  
  const intervalRef = useRef();

  useEffect(() => {
    let index = 0; 
    
    clearInterval(intervalRef.current);
    
    if(!message.length) return;

    intervalRef.current = setInterval(() => {
      setTxt(t => t + message[index]);
      
      index += 1;

      if(index === message.length) {
        index=0;
        setTxt('');
      };
    }, 1000);

    return () => clearInterval(intervalRef.current); 
  }, [message, intervalRef, setTxt]);
  
  return [txt];
};

const Loader = ({ message }) => {
  const [txt] = useTypewriter(message);
  
  return <h2 color='lightskyblue' fontWeight='bold'>Calculating{txt}</h2>
};

const message = [".", ".", ".", ""];

const Line = (props) => {
  const { service, art, name, description, followers, score, playlist_uri, avg_expected_weekly_streams, language, playlist_url } = props; 
  const playlist_id = service === 'spotify' ? playlist_uri.substring(17) : '';
  const nu_playlist_url = service === 'spotify' ? 'https://open.spotify.com/playlist/'.concat(playlist_id) : playlist_url;
  const formatted_followers = service === 'spotify' ? followers.toLocaleString('en-US') : '';
  let formatted_streams = ''; 
  
  if(avg_expected_weekly_streams===null) { 
    formatted_streams = 'unknown';
  } else {
    formatted_streams = Math.ceil(avg_expected_weekly_streams).toLocaleString('en-US'); 
  }

  return ( // remove followers for apple
    <tr noGutters={false} className='mt-1'>
          <td><a href={nu_playlist_url} target='_blank' rel='noreferrer'><img src={art} alt={art} width="100"></img></a></td>
          <td>{name}</td>
          <td>{language}</td>
          <td>{description}</td>
          { service === 'spotify' && <td>{formatted_followers}</td> }
          <td>{formatted_streams}</td>
          <td>{score}</td>
        </tr>
  );
}

const ResultsBody = (props) => {
  const { filteredPlaylistJSON, page, service } = props;
  
  let start_num = (page - 1) * 10;
  let end_num = start_num + 10;

  let final_num = Object.keys(filteredPlaylistJSON).length;
  
  if (end_num > final_num) {
    end_num = final_num
  }

  let renderArray = new Array();

  if(service === 'spotify') {
    for (let i = start_num; i < end_num; i++) { 
      renderArray.push(<Line key={i} service={service} art={filteredPlaylistJSON[i]['playlist_image_url']} name={filteredPlaylistJSON[i]['playlist']} description={filteredPlaylistJSON[i]['playlist_description']} followers={filteredPlaylistJSON[i]['follower_count']} score={filteredPlaylistJSON[i]['aggregated_score']} playlist_uri={filteredPlaylistJSON[i]['playlist_uri']} avg_expected_weekly_streams={filteredPlaylistJSON[i]['avg_expected_weekly_streams']} language={filteredPlaylistJSON[i]['language']} playlist_url=''/>);
    }
  } else {
    for (let i = start_num; i < end_num; i++) { 
      renderArray.push(<Line key={i} service={service} art={filteredPlaylistJSON[i]['playlist_image_url']} name={filteredPlaylistJSON[i]['playlist']} description={filteredPlaylistJSON[i]['playlist_description']} followers='' score={filteredPlaylistJSON[i]['aggregated_score']} playlist_uri='' avg_expected_weekly_streams={filteredPlaylistJSON[i]['avg_expected_weekly_streams']} language={filteredPlaylistJSON[i]['language']} playlist_url={filteredPlaylistJSON[i]['playlist_url']}/>);
    }
  }

  let frag_key = 'page' + page.toString();

  return (
    <React.Fragment key={frag_key}>
    {renderArray}
    </React.Fragment>
  );
}

const ExportFileComponent = (props) => {
  const csvLink = useRef()

  const getExportFile = () => {
    csvLink.current.link.click();
  }

  return (
    <div>
      <Button onClick={getExportFile} className='custom-btn'>Download Results</Button>
      <CSVLink
         data={props.exportFile}
         filename={`${props.inputisrc}_${props.service}_playlist_results.csv`}
         className='hidden'
         ref={csvLink}
         target='_blank'
      />
    </div>
  )
}

class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { service: 'spotify', value: '', inputisrc: '', playlistJSON: null, filteredPlaylistJSON: null, filter_language: null, page: 1, artist: '', title: '', genres: '', moods: '', tag_quality: '', unique_languages: ['es',
    'en',
    'pt',
    'fi',
    'ru',
    'fr',
    'ja',
    'nl',
    'pl',
    'ca',
    'sv',
    'th',
    'cs',
    'tr',
    'de',
    'af',
    'no',
    'it',
    'ro',
    'ko',
    'zh-tw',
    'da',
    'he',
    'zh-cn',
    'vi',
    'tl',
    'id']};

    this.handleChange = this.handleChange.bind(this);
    this.handleISRCSubmit = this.handleISRCSubmit.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.updateFilteredList = this.updateFilteredList.bind(this);
    this.changeService = this.changeService.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  findByValue(o, key) {     
    let temp_languages = [];
    //console.log(o);
    //console.log(typeof(o))
    for (var i = 0; i < Object.keys(o).length; i++) {
      temp_languages.push(o[i][key]);
      console.log(o[i][key]);
    }
    
    let unique_temp_languages = [...new Set(temp_languages)];
    //console.log(unique_temp_languages);
    return unique_temp_languages;
  };

  handleISRCSubmit(event) { // Updated for Apple!
    const that = this;
    if ( (this.state.value.length<12|this.state.value.length>13) && ( this.state.service === 'apple' || !this.state.value.startsWith('spotify:track') ) ) { // may need to double check this in prod
      that.state.service === 'spotify' ? that.setState( { value: 'please enter a valid ISRC or Spotify URI'} ) : that.setState( { value: 'please enter a valid ISRC'} );
      event.preventDefault();
      return;
    }
    that.setState({inputisrc: this.state.value, filteredPlaylistJSON: null});
    
    const requestOptions = {
      crossDomain:true,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    let fetchString = this.state.service === 'spotify' ? `https://backend-dot-umg-mii.uc.r.appspot.com/api/v3/results?isrc=${this.state.value}&key=se9B9vFKrnKSCxxHgkRq4M75sgbZ6h4MN6Q1Rd5jHmE` : `https://backend-dot-umg-mii.uc.r.appspot.com/api/v1/apple_results?isrc=${this.state.value}&key=se9B9vFKrnKSCxxHgkRq4M75sgbZ6h4MN6Q1Rd5jHmE`;

    //fetch(`http://127.0.0.1:5000/api/v3/results?isrc=${this.state.value}&key=se9B9vFKrnKSCxxHgkRq4M75sgbZ6h4MN6Q1Rd5jHmE`, requestOptions)
    //fetch(`https://backend-dot-umg-mii.uc.r.appspot.com/api/v3/results?isrc=${this.state.value}&key=se9B9vFKrnKSCxxHgkRq4M75sgbZ6h4MN6Q1Rd5jHmE`, requestOptions) // send a diff request if apple
    fetch(fetchString, requestOptions)
      .then(response => response.json())
      .catch(err => {
        that.setState({inputisrc: ''});
        console.log(err);
      })
      .then(data => that.setState({ playlistJSON : data[1], filteredPlaylistJSON: data[1], unique_languages: [... new Set(this.findByValue(data[1], 'language').join(', ').split(", "))], artist: data[0]['artist'], title: data[0]['title'], genres: data[0]['genres'], moods: data[0]['moods'], tag_quality: data[0]['tag_quality'] }));

    that.setState( {page: 1, filter_language: null})

    /* log search in Airtable */
    const atRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ User: this.props.userEmail, ISRC: this.state.value})
    };

    fetch('https://playlist-impact-tracking.herokuapp.com/clicks', atRequestOptions);

    event.preventDefault();
  }

  updateFilteredList(a_language) {
    const newArray = Object.values(this.state.playlistJSON).filter(x => x.language.includes(a_language));
    const exportCSV = jsonToCSV(newArray);
    this.setState({ page: 1, filteredPlaylistJSON: newArray });
    console.log(this.state.filteredPlaylistJSON);
  }

  resetFilteredList() {
    this.setState({ page: 1, filteredPlaylistJSON: this.state.playlistJSON });
  }

  handleNext(event) {
    const prevPage = this.state.page;
    let maxPage = Object.keys(this.state.filteredPlaylistJSON).length / 10

    if (maxPage % 1 !== 0) {
      maxPage++;
    }
    
    if (prevPage < maxPage) {
      this.setState({ page: prevPage + 1 });
    }
  }

  handlePrevious(event) {
    const prevPage = this.state.page;
    
    if (prevPage !== 1) {
    this.setState({ page:prevPage - 1 });
    }
  }

  changeService(event) {
    if (this.state.service === 'spotify') {
      //this.setState( { service: 'apple', value: '', inputisrc: '', playlistJSON: null, filteredPlaylistJSON: null, filter_language: null, page: 1, artist: '', title: '', genres: '', moods: '', tag_quality: '' } );
      this.setState( { service: 'apple', inputisrc: '', playlistJSON: null, filteredPlaylistJSON: null, filter_language: null, page: 1, artist: '', title: '', genres: '', moods: '', tag_quality: '' } );
    } else {
      this.setState( { service: 'spotify', inputisrc: '', playlistJSON: null, filteredPlaylistJSON: null, filter_language: null, page: 1, artist: '', title: '', genres: '', moods: '', tag_quality: '' } );
    }
  }

  render () {
    return ( // input box placeholder will need to be a bit different, h2 will be swapped out
      
      <div className="App">
        <h1 className='name-text'>Playlist Targeting</h1>
        { this.state.service === 'spotify' ? <h2 className='spotify-name-text'>Spotify Playlist Recommendations <input type="submit" value=">" onClick={this.changeService} className='apple-btn'/></h2> : <h2 className='apple-name-text'>Apple Playlist Recommendations <input type="submit" value=">" onClick={this.changeService} className='spotify-btn'/></h2> }
        {}
        <form>
          <label>
           { this.state.service === 'spotify' ? <input type="text" value={this.state.value} placeholder='ISRC / URI' onChange={this.handleChange} className='input-box'/> : <input type="text" value={this.state.value} placeholder='ISRC' onChange={this.handleChange} className='input-box'/> }
          </label>
          <input type="submit" value="Search" onClick={this.handleISRCSubmit} className='input-button'/>
        </form>
        
        {this.state.inputisrc.length>=1 && this.state.filteredPlaylistJSON===null && <Loader message="... "/>}

        {this.state.filteredPlaylistJSON!==null && this.state.filteredPlaylistJSON===undefined && <h2 color='lightskyblue' fontWeight='bold'>Error: please try again</h2>}
        
        {this.state.filteredPlaylistJSON!==null && this.state.filteredPlaylistJSON!==undefined &&
        <Container fluid>
          
          

          <Row>
          <Col><h3 className='name-text'>{this.state.artist}</h3></Col>
          <Col>
          
            {this.state.genres!==''&&
            (this.state.genres.includes(',') ?
            <h5 className='trait-text'>Genres: {this.state.genres}</h5> :
            <h5 className='trait-text'>Genre: {this.state.genres}</h5>)}
            </Col>
          </Row>

          <Row>
          <Col><h3 className='name-text'>{this.state.title}</h3></Col>
          
            
            <Col>
            {this.state.moods!==''&&
            (this.state.moods.includes(',') ?
            <h5 className='trait-text'>Moods: {this.state.moods}</h5> :
            <h5 className='trait-text'>Mood: {this.state.moods}</h5>)}
            </Col>

          </Row>

          <Row className='space-on-bottom'>
            <Col>
            <h5>Tag Quality: {this.state.tag_quality}</h5>
            </Col>
          </Row>

          <Row>
          <Col>{this.state.page!==1&&<input type="submit" value="<" onClick={this.handlePrevious} className='arrow-btn'/>}</Col>
          <Col className='custom-page-display'>Page: {this.state.page}</Col>
          <Col>{(Object.keys(this.state.filteredPlaylistJSON).length / 10)>this.state.page&&<input type="submit" value=">" onClick={this.handleNext} className='arrow-btn'/>}</Col>
          </Row>
          
          
          
          <Table hover>
          <thead className='mt-1'>
            <th className='custom-btn'>Art</th>
            <th className='custom-btn'>Name</th>
            <th>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className='custom-dropdown-btn'>Language</Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item key={'all'} onClick={() => {this.resetFilteredList()}}>all</Dropdown.Item>
              {this.state.unique_languages.map(each_language => <Dropdown.Item key={each_language} onClick={() => {this.updateFilteredList(each_language)}}>{each_language}</Dropdown.Item>)}
              </Dropdown.Menu>
            </Dropdown>
            </th>
            <th className='custom-btn'>Description</th>
            { this.state.service === 'spotify' && <th className='custom-btn'>Followers</th> }
            <th className='custom-btn' data-tip data-for="avgTip">Avg Weekly Streams</th>
            
            
            
            <th className='custom-btn' data-tip data-for="scoreTip">Score</th>
            
          </thead>
          <ReactTooltip id="avgTip" place="top" effect="solid" delayShow='400' backgroundColor='lightskyblue' textColor='black'>Average weekly streams is calculated based on averaged playlist streams per track on a recent week and is updated biweekly.</ReactTooltip>
            <ReactTooltip id="scoreTip" place="top" effect="solid" delayShow='400' backgroundColor='lightskyblue' textColor='black'>This score is an aggregate of similarity by creative metadata - your input track is compared to data profiles calculated and regularly updated for each playlist - along with presumed impact and language match.</ReactTooltip>
          <tbody className='table-body'>
          
          
          <ResultsBody filteredPlaylistJSON={this.state.filteredPlaylistJSON} page={this.state.page} service={this.state.service}/>
          
         
          </tbody>
          </Table>
                
          <Row className='final-row'>
            <Col className='mt-2'>
            <ExportFileComponent className='custom-dropdown-btn' service={this.state.service} filteredPlaylistJSON={this.state.filteredPlaylistJSON} inputisrc={this.state.inputisrc} exportFile={jsonToCSV(Object.values(this.state.filteredPlaylistJSON))}/>
            </Col>
          </Row>
   
        </Container>
        }
      </div>
      

    ); 
  }
}

export default App;
